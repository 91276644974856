import { type Messages } from "@/app"
import { type LiqaElement, NAME } from "../web-component"
import { props2attrs } from "./utils"

/*
 * LIQA Services v4 Backward compatibility
 */

/**
 * An imperative way to create LIQA web component.
 * @example
 * ```javascript
 * const liqa = new Liqa({
 *   license: "xxx-xxx-xxx",
 *   target: document.querySelector("#liqa-container"),
 *   styles: "@import url('/path/to/custom-liqa-styles.css')"
 * })
 * ```
 * @category Component (imperative)
 * @deprecated Use the {@link LiqaElement <hautai-liqa>} web component instead.
 */
export function Liqa(config: LiqaConfig): LiqaElement {
  const { target, ...props } = config
  const liqa = document.createElement(NAME) as LiqaElement

  for (const [key, value] of Object.entries(props2attrs(props))) {
    let str = String(value)
    if (str === "[object Object]") str = JSON.stringify(value)
    liqa.setAttribute(key, str)
  }

  if (target) {
    const container = typeof target === "string" ? document.querySelector(target) : target
    if (container) container.appendChild(liqa)
    else console.warn(`[LIQA] Target element "${target}" does not exists.`)
  }

  return liqa
}

/**
 * Options to configure the LIQA‘s look and behavior.
 * @category Component (imperative)
 * @deprecated Use the {@link LiqaElement <hautai-liqa>} web component with the {@link LiqaAttributes} instead.
 */
export type LiqaConfig = {
  /** The license key obtained from {@link https://haut.ai/#contacts Haut.ai }.  */
  license: string
  /**
   * Query selector to the target element or HTMLElement to render LIQA to.
   * @example
   * document.querySelector("#liqa-container")
   */
  target?: Element | string
  /**
   * A string containing valid CSS rules.
   * @example
   * "@import url('/path/to/custom-liqa-styles.css')"
   */
  styles?: string
  /**
   * An object whose keys are messages keys and values are messages texts.
   * @example
   * { "preview": { "submit": "Confirm" } }
   */
  messages?: Messages
  /**
   * A preset used to customize AI quality criteria and user flow.
   * @default "face"
   */
  preset?: "face" | "face-180" | "hair"
  /**
   * A list of sources to capture the image from: different device cameras or file upload.
   * Check the relevant [user flow customization](/customization/user-flow/image-sources) section to learn about the logic.
   *
   * @default ["front_camera"]
   * @alias `["camera"]`
   * Starting from `6.7.0` version `"camera"` acts as an alias for `"front_camera"`.
   */
  sources?:
    | ["camera" | "front_camera" | "back_camera"]
    | ["upload"]
    | ["camera" | "front_camera" | "back_camera", "upload"]
  /**
   * Image capture mode for `"camera"` source.
   * `"auto"` means LIQA will automatically take a photo when all AI quality criteria met.
   * `"manual"` means LIQA will allow the user to take a photo on their own via a button.
   * The option is only applicable to "hair" preset. For other presets it is always "auto".
   */
  capture?: "auto" | "manual"
}
