import { DEFAULT_PRESET, type Props } from "./api"

export const PreloadByPreset = {
  face: () => import("@/features/face/preset/preload").then(({ preload }) => preload),
  "face-180": () => import("@/features/face-180/preset/preload").then(({ preload }) => preload),
  hair: () => import("@/features/hair/preset/preload").then(({ preload }) => preload),
}

/**
 * Preloads preset‘s resources in advance.
 * @param options - Preload options.
 * @example
 * ```ts
 * preload({ preset: "face" })
 * ```
 * @category Misc
 */
export const preload = async ({ preset = DEFAULT_PRESET }: PreloadOptions = {}) => {
  const loader = PreloadByPreset[preset]

  if (!loader) {
    console.warn(`Unknown preset "${preset}". Skipping assets preloading.`)
    return
  }

  await loader().then((preload) => preload())
}

/**
 * @category Misc
 */
export type PreloadOptions = Pick<Props, "preset">
