/**
 * Extracts the JavaScript module bundled relative URL as well as the bundled relative URLs of CSS dependencies of the module.
 * @param loader - A module loader function, e.g `() => import("./example.js")`
 * @returns js and css dependencies of the module
 * @example
 * ```js
 * const { js, css } = dependencies(() => import("./example.js"))
 * console.log(js) // "./example-17ac2ab0.js"
 * console.log(css) // ["./assets/index-18db47ac.css", "./assets/example-2ab088bf.css"]
 * ```
 */
export const dependencies = (loader: () => Promise<unknown>) => {
  /*
   * When the codebase bundled with Vite, the dynamic import in the loader function turns into the helper code
   * ensuring that all the module CSS dependencies are preloaded before the module is executed:
   * ```js
   * viteModulePreloadHelper(
   *   () => import("./example-17ac2ab0.js"),
   *   ["./assets/index-18db47ac.css", "./assets/example-2ab088bf.css"],
   *   import.meta.url,
   * )
   * ```
   * It is possible to extract the module bundled URL as well as URL of the CSS dependencies from the helper code.
   *
   * Implementation inspirations:
   * - Vite‘s dynamic import transformation implementation: https://github.com/vitejs/vite/blob/main/packages/vite/src/node/plugins/importAnalysisBuild.ts#L250-L257
   * - Vite‘s module preload helper implementation: https://github.com/vitejs/vite/blob/cc388d9c84e44a9b7d990dfbd3023aaf20494ecd/packages/vite/src/node/plugins/importAnalysisBuild.ts#L74
   */
  const code = String(loader)
  const [js] = extract(code, /"([^"]+\.(j|t)s)"/g) // "ts" is used to support the dependencies extraction during local development (npm run dev)
  const css = extract(code, /"([^"]+\.css)"/g)
  return { js, css }
}

const extract = (string: string, re: RegExp) => [...string.matchAll(re)].map((m) => m[1])
